import React, { useState, useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import { Button, Card, CardContent, Grid } from '@material-ui/core';
import { FormGroup, useStyles, ButtonWrapperFilter, Splitter } from '../../styles/common-styles'
import { SelectInput } from '../Forms'
import { useFormik } from 'formik';
import PopupHeader from '../../pages/common-page/popupHeader'
import { useTranslation } from 'react-i18next';
import { financialAid_addUserList } from "../../services/navigation_folder/financialAid"
import { student_notes_getDropDown, student_notes_getListData, student_notes_url } from '../../services/apiURL';
import { getApi } from '../../services/apiBase';
import GenericModal from '../Modal/GenericModal';
import { admission_addUserList } from '../../services/navigation_folder/admissions';
import { registration_add_userList } from '../../services/navigation_folder/registration';
const AddRadioGroup = ({ onClose, setGroupAccess, selectedRow, gropuAccess, accessFlag }) => {
  const { t } = useTranslation(['translation', 'financialAid']);
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);
  const leftChecked = intersection(checked, left);

  const rightChecked = intersection(checked, right);
  const [loadPopup, setLoadPopup] = React.useState(false)
  const initialValues = {
    "campus": "",
    "department": "",

  };
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      // setLoadPopup(true);
      getApi(`${student_notes_getListData}/${formik.values.campus === "" ? 0 : formik.values.campus}/${formik.values.department === "" ? 0 : formik.values.department}`).then((resp) => {
        setLeft(resp.data.data.map(value => value.camsUser));

      }).catch((err) => {
        console.log(err);
      })

    }
  });

  function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
  }
  function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
  }



  const [Dropdown, setDropdown] = useState({
    "campus": [],
    "department": []
  });

  //API Call for dropdown
  useEffect(() => {
    getApi(`${student_notes_url}`).then((resp) => {
      const { campuses, departments } = resp.data.data
      setDropdown({
        ...Dropdown,
        "campus": campuses,
        "department": departments
      })
    }).catch((err) => {
      console.log(err);
    })
  }, [])




  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {


    if (selectedRow.groupAccess !== undefined && selectedRow.groupAccess !== "" && gropuAccess !== "") {
      let grbAccess = gropuAccess.split(',')
      let withoutExisting = not(leftChecked, grbAccess)
      setRight(right.concat(withoutExisting));
    }
    if (selectedRow.groupAccess !== undefined && selectedRow.groupAccess !== "" && gropuAccess === "") {
      let _groubAccess = selectedRow.groupAccess.split(',')
      let withoutExistingRigt = not(leftChecked, _groubAccess)
      setRight(right.concat(withoutExistingRigt));
    }
    else {
      setRight(right.concat(leftChecked));
    }
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {

    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };


  useEffect(() => {

    if (gropuAccess !== "" && selectedRow !== "") {
      let groupAccessArrayTemp = gropuAccess.split(',');
      setRight(groupAccessArrayTemp);
    }
    if (gropuAccess !== "" && selectedRow === "") {
      let groupAccessArrayTemp = gropuAccess.split(',');
      setRight(groupAccessArrayTemp);
    }
    if (gropuAccess === "" && selectedRow !== "") {
      if (selectedRow.groupAccess !== "") {
        let groupAccessArrayTemp = selectedRow.groupAccess.split(',');
        setRight(groupAccessArrayTemp);
      }

    }
    else {

      if (selectedRow.groupAccess !== undefined && selectedRow.groupAccess !== "") {
        let groupAccessArray = selectedRow.groupAccess.split(',');
        setRight(groupAccessArray);
      }

    }



  }, []);

  const classes = useStyles();

  const customList = (items) => (
    <Paper sx={{ width: 230, height: 200, overflow: 'auto' }}>
      <List dense component="div" role="list">
        {items.map((value) => {
          const labelId = `${value}-label`;

          return (
            <ListItem
              key={value}
              role=""
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value}`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );
  const getErrorPopup = () => {
    setGroupAccess(String(right));
    right.length <= 0 ? setLoadPopup(true) : onClose('')
  }
  const resetForm = () => {
    formik.resetForm();
    setLeft([]);
    // setTableRow([]);
  }
  const [breadcrumb, setBreadcrumb] = useState(financialAid_addUserList)
  useEffect(()=>{
      if(window.location.href.indexOf('/admissions/students/student-notes') > -1 ){
          setBreadcrumb(admission_addUserList)
      }
      else if(window.location.href.indexOf('/financial-aid/student-notes') > -1 ){
          setBreadcrumb(financialAid_addUserList)
      }
      else if(window.location.href.indexOf('/registration/academic/student-notes') > -1 ){
        setBreadcrumb(registration_add_userList)
    }
  },[])

  return (
    <>
      {(right.length) <= 0 ?
        <GenericModal title={t('popupMessages.groupAccessRequiresthatMembersbeAssignedtotheGroup.toExitNow,pleaseCancelGroupAccess')} open={loadPopup} onClose={setLoadPopup}
          buttonText='Ok'
        // onButtonPress={onButtonPress}
        /> : null}
      <Grid >
        <form onSubmit={formik.handleSubmit} autoComplete="off" className={classes.root}>
          <Card>
            <CardContent>
              <PopupHeader title={t('commonLabel.userListCriteria', { ns: "financialAid" })} navigationList={breadcrumb} onClose={onClose} />
              <FormGroup>
                <SelectInput
                  label={t('commonLabel.campus', { ns: "financialAid" })}
                  labelId={'campus'}
                  isDoubled
                  formikValue={formik.values.campus}
                  formikChange={formik.handleChange}
                  disabled={accessFlag}
                  dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.campus }}
                />
                <SelectInput
                  labelId="department"
                  label={t('commonLabel.department', { ns: "financialAid" })}
                  isDoubled
                  formikValue={formik.values.department}
                  disabled={accessFlag}
                  formikChange={formik.handleChange}
                  dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.department }}

                />
              </FormGroup>
              <ButtonWrapperFilter>
                  <Button disabled={accessFlag} aria-label={t('buttonLabel.search')} className='BgYellowOrange search' variant="contained" type="submit">{t('buttonLabel.search')}</Button>
                <Button aria-label={t('buttonLabel.reset')} className="BgLightBlue" variant="contained" size="large" type="reset"
                  // onClick={e => { setAddRadioGroup(true); }} 
                  onClick={resetForm}
                >{t('buttonLabel.reset')}</Button>
              </ButtonWrapperFilter>
              <Splitter />
              <FormGroup>
                <Grid style={{ marginLeft: '27%' }} item>{t('commonLabel.userList', { ns: "financialAid" })}{customList(left)}  </Grid>
                <Grid item>
                  <Grid style={{ marginTop: '50px', marginLeft: '19%' }} container direction="column" alignItems='center' >
                    <Button
                      sx={{ my: 0.5 }}
                      variant="outlined"
                      size="small"
                      className='addNotesButton'
                      onClick={handleCheckedRight}
                      // disabled={left.length === 0}
                      aria-label="move all right"
                    >
                      +
                    </Button>
                    <Button
                      sx={{ my: 0.5 }}
                      variant="outlined"
                      size="small"
                      className='addNotesButton'
                      onClick={handleAllRight}
                      // disabled={leftChecked.length !== 0}
                      aria-label="move selected right"
                    >
                      +All
                    </Button>
                    <br></br>
                    <Button
                      sx={{ my: 0.5 }}
                      variant="outlined"
                      size="small"
                      className='addNotesButton'
                      onClick={handleCheckedLeft}
                      // disabled={rightChecked.length !== 0}
                      aria-label="move selected left"
                    >
                      -
                    </Button>
                    <Button
                      sx={{ my: 0.5 }}
                      variant="outlined"
                      size="small"
                      className='addNotesButton'
                      onClick={handleAllLeft}
                      // disabled={rightChecked.length !== 0}
                      aria-label="move all left"
                    >
                      -All
                    </Button>
                  </Grid>
                </Grid>
                <Grid style={{ marginRight: '25%' }} item>{t('commonLabel.noteGroupList', { ns: "financialAid" })}{customList(right)}</Grid>
              </FormGroup>
              <Splitter />
              <ButtonWrapperFilter>
                <Button disabled={accessFlag} aria-label={t('buttonLabel.ok')} className='BgYellowOrange search' variant="contained"
                  onClick={getErrorPopup}
                >{t('buttonLabel.ok')}</Button>
                <Button aria-label={t('buttonLabel.cancel')} className="BgLightBlue" variant="contained" size="large" onClick={() => onClose('')} >{t('buttonLabel.cancel')}</Button>
              </ButtonWrapperFilter>
            </CardContent>
          </Card>
        </form>

      </Grid>
    </>
  );
}

export default AddRadioGroup
