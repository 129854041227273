import React, { useState, useEffect, useRef } from 'react';
import { FormInputWrapper } from '../../styles/common-styles'
import { InputLabel, TextField, FormHelperText, TextareaAutosize, Tooltip } from '@material-ui/core';
import BusinessIcon from '@mui/icons-material/Business';
import GenericModal from '../Modal/GenericModal';
import { useTranslation } from 'react-i18next';
import InputAdornment from '@mui/material/InputAdornment';
import Autocomplete from '@mui/material/Autocomplete';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import { phoneInputMask, phoneMaskingPatternWise, phoneKeyInputMask } from '../../helper/formatePhone';
import { formatSSNVisibility } from '../../helper/formatSSN';

/**
 * @param {{
 * label: string, 
 * labelId: string, 
 * formikValue: Object, 
 * formikChange: Function, 
 * isDoubled?: Boolean,
 * disabled:boolean,
 * errors?: formik.errors.labelId,
 * touched?: formik.touched.labelId,
 * mandatory: Boolean
 * maxlength: String
 * maxValue: Number
 * onlyNumeric: Boolean
 * onlyAlphanumeric:Boolean
 * customStyle: css
 * }} props 
 */
// for type number AND maxlength, need
//  * maxValue: Number
//  * onlyNumeric: Boolean


export const TextInput = (props) => {
    const [formInvalidAlerts, setFormInvalidAlerts] = useState({ errShow: false, errMessage: '' });
    const { t } = useTranslation();
    const { errMsgCustom, onlyAlphabetFirst, formik, customStyle, label, labelId, formikValue, formikChange, disabled, isDoubled, isCustomised, errors, helperText, mandatory, maxValue, minValue, maxlength, onBlur, touched, onlyNumeric, type, onlyAlphanumeric, errMsg, hoverText, adornment, placeholder, phoneFormat=false, maskPattern, ssnMask,ssnSymbolicPattern, onTooltip } = props;
    // const isHidden = hide=='hide' ? false : true;,
    let maxValues = (maxValue) ? maxValue : 2147483647;
    let minValues = (minValue) ? minValue : -2147483647;
    const [onHoverText, setHoverText] = useState('');
    const [filled, setFilled] = useState('');
    useEffect(() => {
        if(formikValue){
            setFilled('filled')
        }
    }, [formikValue])

    return (
        <FormInputWrapper style={customStyle} isDoubled={isDoubled ? isDoubled : null} isCustomised={isCustomised ? isCustomised : null} className={isDoubled ? 'doubled' : null}>
            <InputLabel htmlFor={labelId}>{label ? label : <>&nbsp;</>}<span className={(mandatory) ? 'asterisk' : ''}>{(mandatory) ? '*' : ''}</span></InputLabel>
            <Tooltip title={onTooltip ? onTooltip : ""} arrow >
                <TextField
                    className={filled}
                    data-testid={'search-text-field'}
                    disabled={disabled}
                    error={(errors && touched) ? true : false}
                    id={labelId}
                    name={labelId}
                    type={type ? type : 'text'}
                    variant="outlined"
                    onChange={
                        (event) => {
                            if(phoneFormat) {
                                let value = phoneInputMask(event.target.value, maskPattern);
                                event.target.value = value;
                                return formikChange(event)
                            }
                            if(ssnMask) {
                                let value =  formatSSNVisibility(event.target.value, ssnSymbolicPattern);
                                event.target.value = value;

                                return formikChange(event)
                            }
                            if (onlyNumeric) {
                                if (event.target.value.match(/^-?\d*\.?\d*$/)) {
                                    if ((parseFloat(event.target.value) <= minValues)) {
                                        setFormInvalidAlerts({ errShow: true, errMessage: `${errMsgCustom ? errMsgCustom :
                                                errMsg ? errMsg : t('popupMessages.Numberoutsideofallowedrange')}  
                                    ${ (errMsgCustom) ? '' : (maxValue) ? `${(minValue) ? minValue : 0} - ${maxValue}` : `-2147483648 to +2147483647`}` });
                                        return formikChange(-1);
                                    }
                                    // Error handlers: Numeric range
                                    if ((parseFloat(event.target.value) >= 0) && (parseFloat(event.target.value) > maxValues)) {
                                        setFormInvalidAlerts({ errShow: true, errMessage: `${errMsgCustom ? errMsgCustom :
                                                errMsg ? errMsg : t('popupMessages.Numberoutsideofallowedrange')} 
                                    ${ (errMsgCustom) ? '' : (maxValue) ? `${(minValue) ? minValue : 0} - ${maxValue}` : `-2147483648 to +2147483647`}` });
                                        return formikChange(-1);
                                    }
                                    return formikChange(event);
                                }
                            } else if (onlyAlphanumeric) {
                                if (event.target.value == "") return formikChange(event);
                                if (event.target.value.match(/^\w+$/)) {
                                    return formikChange(event);
                                }
                            } else if (onlyAlphabetFirst) {
                                if (event.target.value == "") return formikChange(event);
                                if (event.target.value.charAt(0).match(/^[a-zA-Z]+$/)) {
                                    return formikChange(event);
                                }
                            } else return formikChange(event);
                        }
                    }
                    onKeyPress={(event) => {
                        if(phoneFormat) {
                            let value = phoneInputMask(event.target.value, maskPattern);
                            event.target.value = value;
                            return formikChange(event)
                        }
                    }}
                    onMouseOver={(e) => { hoverText && setHoverText(hoverText) }} onMouseOut={(e) => { hoverText && setHoverText('') }}
                    onBlur={(e) => {
                        let value = e.target.value;
                        if(value){
                            setFilled('filled')
                        }else{
                            setFilled('')
                        }
                        onBlur && onBlur(e);
                    }}
                    value={formikValue}
                    inputProps={{
                        maxLength: maxlength ? maxlength : '100',
                        max: 10, autoComplete: 'off',
                        "aria-required": mandatory ? true : null
                    }}
                    InputProps={adornment ? adornment : ""}
                    placeholder={placeholder ? placeholder : ''}
                    autocomplete={false}
                />
            </Tooltip>
            {formInvalidAlerts['errShow'] &&
                <GenericModal
                    open={formInvalidAlerts['errShow']}
                    onClose={() => setFormInvalidAlerts({ errShow: false, errMessage: '' })}
                    error={formInvalidAlerts}
                    buttonText='Ok'
                    onButtonPress={() => (formik) ? formik.setFieldValue(labelId, 0) : ""}
                />}
            {(helperText) ?
                errors && helperText && <FormHelperText className="helperText">{helperText}</FormHelperText> :
                errors && touched && <FormHelperText className="helperText">{errors}</FormHelperText>}
            {onHoverText && <FormHelperText className="hoverTextAccess">{onHoverText}</FormHelperText>}
        </FormInputWrapper>
    )
}

/**
 * @param {{
 * label: string, 
 * labelId: string, 
 * formikValue: Object, 
 * formikChange: Function, 
 * isDoubled?: Boolean,
 * disabled:boolean,
 * errors?: formik.errors.labelId,
 * touched?: formik.touched.labelId,
 * mandatory: Boolean
 * maxlength: String
 * maxValue: Number
 * onlyNumeric: Boolean
 * onlyAlphanumeric:Boolean
 * customStyle: css
 * handlelookUp: Function
 * iconHide: Boolean,
 * isIconDisabled: Boolean
 * }} props 
 */
// for type number AND maxlength, need
//  * maxValue: Number
//  * onlyNumeric: Boolean
export const TextEditableLookupInput = (props) => {
    const [formInvalidAlerts, setFormInvalidAlerts] = useState({ errShow: false, errMessage: '' });
    const { t } = useTranslation();
    const { onlyAlphabetFirst, formik, customStyle, label, labelId, formikValue, formikChange, disabled, isDoubled, errors, helperText, mandatory, maxValue, minValue, maxlength, onBlur, touched, onlyNumeric, type, onlyAlphanumeric, errMsg, hoverText, adornment, placeholder, iconHide, isIconDisabled, handlelookUp, customIconStyle } = props;
    // const isHidden = hide=='hide' ? false : true;
    let maxValues = (maxValue) ? maxValue : 2147483647;
    let minValues = (minValue) ? minValue : -2147483647;
    const [onHoverText, setHoverText] = useState('');
    return (
        <FormInputWrapper style={customStyle} isDoubled={isDoubled ? isDoubled : null} className={isDoubled ? 'icon-text doubled' : 'icon-text'}>
            <InputLabel htmlFor={labelId}>{label}<span className={(mandatory) ? 'asterisk' : ''}>{(mandatory) ? '*' : ''}</span></InputLabel>
            <TextField
                disabled={disabled}
                error={(errors && touched) ? true : false}
                id={labelId}
                name={labelId}
                type={type ? type : 'text'}
                variant="outlined"
                onChange={
                    (event) => {
                        if (onlyNumeric) {
                            if (event.target.value.match(/^-?\d*\.?\d*$/)) {
                                if ((parseFloat(event.target.value) <= minValues)) {
                                    setFormInvalidAlerts({ errShow: true, errMessage: `${errMsg ? errMsg : t('popupMessages.Numberoutsideofallowedrange')}  ${(maxValue) ? `${(minValue) ? minValue : 0} - ${maxValue}` : `-2147483648 to +2147483647`}` });
                                    return formikChange(-1);
                                }
                                // Error handlers: Numeric range
                                if ((parseFloat(event.target.value) >= 0) && (parseFloat(event.target.value) > maxValues)) {
                                    setFormInvalidAlerts({ errShow: true, errMessage: `${errMsg ? errMsg : t('popupMessages.Numberoutsideofallowedrange')} ${(maxValue) ? `${(minValue) ? minValue : 0} - ${maxValue}` : `-2147483648 to +2147483647`}` });
                                    return formikChange(-1);
                                }
                                return formikChange(event);
                            }
                        } else if (onlyAlphanumeric) {
                            if (event.target.value == "") return formikChange(event);
                            if (event.target.value.match(/^\w+$/)) {
                                return formikChange(event);
                            }
                        } else if (onlyAlphabetFirst) {
                            if (event.target.value == "") return formikChange(event);
                            if (event.target.value.charAt(0).match(/^[a-zA-Z]+$/)) {
                                return formikChange(event);
                            }
                        } else return formikChange(event);
                    }
                }
                onKeyPress={(event) => {
                }}
                onMouseOver={(e) => { hoverText && setHoverText(hoverText) }} onMouseOut={(e) => { hoverText && setHoverText('') }}
                onBlur={onBlur}
                value={formikValue}
                inputProps={{
                    maxLength: maxlength ? maxlength : '100',
                    max: 10, autoComplete: 'off',
                }}
                InputProps={adornment ? adornment : ""}
                placeholder={placeholder ? placeholder : ''}
            />
            {(!iconHide) ? <BusinessIcon style={customIconStyle} className={isIconDisabled ? 'icon-disabled' : ''} onClick={() => handlelookUp()} data-label={labelId} /> : null}

            {formInvalidAlerts['errShow'] &&
                <GenericModal
                    open={formInvalidAlerts['errShow']}
                    onClose={() => setFormInvalidAlerts({ errShow: false, errMessage: '' })}
                    error={formInvalidAlerts}
                    buttonText='Ok'
                    onButtonPress={() => (formik) ? formik.setFieldValue(labelId, 0) : ""}
                />}
            {(helperText) ?
                errors && helperText && <FormHelperText className="helperText">{helperText}</FormHelperText> :
                errors && touched && <FormHelperText className="helperText">{errors}</FormHelperText>}
            {onHoverText && <FormHelperText className="asterisk">{onHoverText}</FormHelperText>}
        </FormInputWrapper>
    )
}

/**
 * @param {{
 * label: string, 
 * labelId: string, 
 * formikValue: Object, 
 * handlelookUp: Function, 
 * isDoubled?: Boolean,
 * disabled: Boolean,
 * errors?: formik.errors.labelId,
 * touched?: formik.touched.labelId,
 * mandatory: Boolean,
 * iconHide: Boolean,
 * formik: Function
 * isIconDisabled: Boolean
 * }} props 
 */
export const TextLookUpInput = (props) => {
    const { customStyle, label, labelId, formikValue, isDoubled, handlelookUp, disabled, errors, touched, mandatory, iconHide, formik, isIconDisabled, formikCustomChange } = props;
    return (
        <FormInputWrapper style={customStyle} isDoubled={isDoubled ? isDoubled : null} className={isDoubled ? 'icon-text doubled' : 'icon-text'}>
            <InputLabel htmlFor={labelId}>{label}<span className={(mandatory) ? 'asterisk' : ''}>{(mandatory) ? '*' : ''}</span></InputLabel>
            <TextField error={(errors && touched) ? true : false} disabled={disabled} id={labelId} name={labelId} type="text" variant="outlined" value={formikValue} onChange={formikCustomChange ?? ((formik) && formik.handleChange)} />

            {(!iconHide) ? <BusinessIcon className={isIconDisabled ? 'icon-disabled' : ''} onClick={() => handlelookUp()} data-label={labelId} /> : null}
            {errors && touched && <FormHelperText className="helperText">{errors}</FormHelperText>}
        </FormInputWrapper>
    )
}

export const TextAreaInput = (props) => {
    const { customStyle, label, labelId, formikValue, isDoubled, disabled, errors, touched, mandatory, formik, formikChange, maxRows, minRows, placeholder,maxlength, onTooltip } = props;
    return (
        <FormInputWrapper style={customStyle} isDoubled={isDoubled ? isDoubled : null} className={isDoubled ? 'icon-text doubled' : 'icon-text'}>
            <InputLabel htmlFor={labelId}>{label}<span className={(mandatory) ? 'asterisk' : ''}>{(mandatory) ? '*' : ''}</span></InputLabel>
            <Tooltip title={onTooltip ? onTooltip : ""} arrow >
                <TextareaAutosize
                    className={(errors && touched) ? 'error-txtarea' : ''}
                    aria-label="minimum height"
                    maxRows={maxRows ? maxRows : 10}
                    minRows={minRows ? minRows : 3}
                    maxlength={maxlength ? maxlength : ''}
                    name={labelId}
                    onChange={formikChange}
                    value={formikValue}
                    disabled={disabled}
                    placeholder={placeholder ? placeholder : ''}
                    style={{ width: '100%', padding: '16px', boxSizing: 'border-box', height: 'auto', overflow: 'auto' }}
                />
            </Tooltip>
            {errors && touched && <FormHelperText className="helperText">{errors}</FormHelperText>}
        </FormInputWrapper>
    )
}
export const TextAreaLookupInput = (props) => {
    const { customStyle, label, labelId, formikValue, isDoubled, handlelookUp, disabled, errors, touched, mandatory, iconHide, formik, formikChange, isIconDisabled } = props;
    return (
        <FormInputWrapper style={customStyle} isDoubled={isDoubled ? isDoubled : null} className={isDoubled ? 'icon-text doubled' : 'icon-text'}>
            <InputLabel htmlFor={labelId}>{label}</InputLabel>
            <TextareaAutosize
                aria-label="minimum height"
                maxRows={10}
                minRows={3}
                name={labelId}
                onChange={formikChange}
                value={formikValue}
                disabled={disabled}
                style={{ width: '95%', padding: '16px', boxSizing: 'border-box', height: 'auto', overflow: 'auto' }}
            />
            {(!iconHide) ? <BusinessIcon className={isIconDisabled ? 'icon-disabled' : ''} style={{ position: 'absolute' }} onClick={() => handlelookUp()} data-label={labelId} /> : null}
            {errors && touched && <FormHelperText className="helperText">{errors}</FormHelperText>}
        </FormInputWrapper>
    )
}
export const TextAutoInputLookup = (props) => {
    const { t } = useTranslation();
    let inputElement = useRef();
    const { onCustomChange, label, labelId, formik, DropdownData, mandatory, errors, touched, isDoubled, disabled, customStyle, iconHide, isTripple,isIconDisabled,handlelookUp } = props;
    const [optionTList, setOptionTList] = React.useState([]);
    const [labelValue, setLabelValue] = useState('');
    const [isAlert, setAlert] = useState(false);
    const [termName, setTermName] = useState(null);
    let inputRef;
    const getTOptions = () => {
        let options = [];
        for (let key in DropdownData) {
            options.push({ id: DropdownData[key].uniqueId, label: DropdownData[key].displayText })
        }
        return options;
    }
    useEffect(() => {
        setOptionTList(getTOptions());
    }, [DropdownData])

    const handleButtonPress = () => {
        setAlert(false);
        inputRef.focus();
    }

    useEffect(() => {
        if (DropdownData.length > 0) {
            const displayText = DropdownData.filter(value => value.uniqueId == formik.values[labelId])[0]?.displayText;
            displayText ? setLabelValue(displayText) : setLabelValue("");
        }
    }, [formik.values[labelId], DropdownData])
    return (
        <>
            {isAlert &&
                <GenericModal title={t('commonLabel.transdocNotFoundAlert', { what: termName })}
                    open={isAlert} onClose={setAlert}
                    onButtonPress={handleButtonPress}
                    buttonText={t('buttonLabel.ok')}
                />
            }
            <FormInputWrapper style={customStyle} isDoubled={isDoubled ? isDoubled : null} isTripple={isTripple ? isTripple : null} className={isDoubled ? 'icon-text doubled' : 'icon-text'}>
                <InputLabel htmlFor="labelValue">{label}<span className={(mandatory) ? 'asterisk' : ''}>{(mandatory) ? '*' : ''}</span></InputLabel>
                <Autocomplete
                    freeSolo
                    id="labelValue"
                    disableClearable
                    options={optionTList}
                    openOnFocus
                    onChange={(event, newValue) => {
                        formik.setFieldValue(labelId, newValue.id)
                        onCustomChange && onCustomChange(event, newValue.id);
                    }}
                    onBlur={(event) => {
                        let isExist = DropdownData.find(etd => etd.displayText === event.target.value)
                        if (!isExist || isExist?.uniqueId === 0 || isExist?.uniqueId === "0") {
                            
                            if(event.target.value !== ""){
                                setTermName(event.target.value);
                                setAlert(true);
                            }
                            formik.setFieldValue(labelId, 0)
                            setLabelValue('');

                        }
                      
                        else {
                            const filteredArray = DropdownData.filter(value => value.displayText == event.target.value);
                            formik.setFieldValue(labelId, filteredArray[0].uniqueId)
                            setLabelValue(event.target.value);
                            //setAlert(false);
                        }
                    }}
                    inputValue={labelValue}
                    disabled={disabled}
                    onInputChange={(event, newInputValue) => {
                        setLabelValue(newInputValue);
                    }}
                    renderOption={(props, option) => {
                        return (
                            <li {...props} key={option.id}>
                                {option.label}
                            </li>
                        );
                    }}
                    renderInput={(params) =>
                        <>
                        <TextField {...params}
                            error={errors && touched ? true : false}
                            disabled={disabled}
                            name={labelId}
                            inputRef={input => {
                                inputRef = input;
                            }}
                            variant="outlined" InputProps={{
                                ...params.InputProps,
                                endAdornment: (!iconHide) && <InputAdornment position="start"><span style={{ cursor: 'pointer', color: '#0000008a' }} className="material-icons-outlined">
                                    info
                                </span></InputAdornment>
                            }} />
                        <BusinessIcon className={isIconDisabled ? 'icon-disabled' : ''} style={{ position: 'absolute' }} onClick={handlelookUp} data-label={labelId} />
            </>
                        }

                />

                {errors && touched && <FormHelperText className="helperText">{errors}</FormHelperText>}
            </FormInputWrapper>
        </>

    )
}
export const TextAutoInput = (props) => {
    const { t } = useTranslation();
    let inputElement = useRef();
    const { onCustomChange, label, labelId, formik, DropdownData, mandatory, errors, touched, isDoubled, disabled, customStyle, iconHide, isTripple } = props;
    const [optionTList, setOptionTList] = React.useState([]);
    const [labelValue, setLabelValue] = useState('');
    const [isAlert, setAlert] = useState(false);
    const [termName, setTermName] = useState(null);
    let inputRef;
    const getTOptions = () => {
        let options = [];
        for (let key in DropdownData) {
            options.push({ id: DropdownData[key].uniqueId, label: DropdownData[key].displayText })
        }
        return options;
    }
    useEffect(() => {
        setOptionTList(getTOptions());
    }, [DropdownData])

    const handleButtonPress = () => {
        setAlert(false);
        inputRef.focus();
    }

    useEffect(() => {
        if (DropdownData.length > 0) {
            const displayText = DropdownData.filter(value => value.uniqueId == formik.values[labelId])[0]?.displayText;
            displayText ? setLabelValue(displayText) : setLabelValue("");
        }
    }, [formik.values[labelId], DropdownData])
    return (
        <>
            {isAlert &&
                <GenericModal title={t('commonLabel.termNotFoundAlert', { what: termName })}
                    open={isAlert} onClose={setAlert}
                    onButtonPress={handleButtonPress}
                    buttonText={t('buttonLabel.ok')}
                />
            }

            <FormInputWrapper style={customStyle} isDoubled={isDoubled ? isDoubled : null} isTripple={isTripple ? isTripple : null} className={isDoubled ? 'doubled' : ''}>
                <InputLabel htmlFor="labelValue">{label}<span className={(mandatory) ? 'asterisk' : ''}>{(mandatory) ? '*' : ''}</span></InputLabel>
                <Autocomplete
                    freeSolo
                    id="labelValue"
                    disableClearable
                    options={optionTList}
                    openOnFocus
                    onChange={(event, newValue) => {
                        formik.setFieldValue(labelId, newValue.id)
                        onCustomChange && onCustomChange(event, newValue.id);
                    }}
                    onBlur={(event) => {
                        let isExist = DropdownData.find(etd => etd.displayText === event.target.value)
                        if (!isExist || isExist?.uniqueId === 0 || isExist?.uniqueId === "0") {
                            
                            if(event.target.value !== ""){
                                setTermName(event.target.value);
                                setAlert(true);
                            }
                            formik.setFieldValue(labelId, 0)
                            setLabelValue('');

                        }
                      
                        else {
                            const filteredArray = DropdownData.filter(value => value.displayText == event.target.value);
                            formik.setFieldValue(labelId, filteredArray[0].uniqueId)
                            setLabelValue(event.target.value);
                            //setAlert(false);
                        }
                    }}
                    inputValue={labelValue}
                    disabled={disabled}
                    onInputChange={(event, newInputValue) => {
                        setLabelValue(newInputValue);
                    }}
                    renderOption={(props, option) => {
                        return (
                            <li {...props} key={option.id}>
                                {option.label}
                            </li>
                        );
                    }}
                    renderInput={(params) =>
                        <TextField {...params}
                            error={errors && touched ? true : false}
                            disabled={disabled}
                            name={labelId}
                            inputRef={input => {
                                inputRef = input;
                            }}
                            variant="outlined" InputProps={{
                                ...params.InputProps,
                                endAdornment: (!iconHide) && <InputAdornment position="start"><span style={{ cursor: 'pointer', color: '#0000008a' }} className="material-icons-outlined">
                                    info
                                </span></InputAdornment>
                            }} />}
                /> 
                {errors && touched && <FormHelperText className="helperText">{errors}</FormHelperText>}
            </FormInputWrapper>
        </>

    )
}

export const TextPasswordInput = (props) => {
    const [formInvalidAlerts, setFormInvalidAlerts] = useState({ errShow: false, errMessage: '' });
    const { t } = useTranslation();
    const { onlyAlphabetFirst, formik, customStyle, label, labelId, formikValue, formikChange, disabled, isDoubled, errors, helperText, mandatory, maxValue, minValue, maxlength, onBlur, touched, onlyNumeric, type, onlyAlphanumeric, errMsg, hoverText, adornment, placeholder } = props;
    // const isHidden = hide=='hide' ? false : true;
    let maxValues = (maxValue) ? maxValue : 2147483647;
    let minValues = (minValue) ? minValue : -2147483647;
    const [onHoverText, setHoverText] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    }

    return (
        <FormInputWrapper style={customStyle} isDoubled={isDoubled ? isDoubled : null} className={isDoubled ? 'doubled' : null}>
            <InputLabel htmlFor={labelId}>{label}<span className={(mandatory) ? 'asterisk' : ''}>{(mandatory) ? '*' : ''}</span></InputLabel>
            <TextField
                disabled={disabled}
                error={(errors && touched) ? true : false}
                id={labelId}
                name={labelId}
                type={showPassword ? "text" : "password"}
                variant="outlined"
                onChange={
                    (event) => {
                        if (onlyNumeric) {
                            if (event.target.value.match(/^-?\d*\.?\d*$/)) {
                                if ((parseFloat(event.target.value) <= minValues)) {
                                    setFormInvalidAlerts({ errShow: true, errMessage: `${errMsg ? errMsg : t('popupMessages.Numberoutsideofallowedrange')}  ${(maxValue) ? `${(minValue) ? minValue : 0} - ${maxValue}` : `-2147483648 to +2147483647`}` });
                                    return formikChange(-1);
                                }
                                // Error handlers: Numeric range
                                if ((parseFloat(event.target.value) >= 0) && (parseFloat(event.target.value) > maxValues)) {
                                    setFormInvalidAlerts({ errShow: true, errMessage: `${errMsg ? errMsg : t('popupMessages.Numberoutsideofallowedrange')} ${(maxValue) ? `${(minValue) ? minValue : 0} - ${maxValue}` : `-2147483648 to +2147483647`}` });
                                    return formikChange(-1);
                                }
                                return formikChange(event);
                            }
                        } else if (onlyAlphanumeric) {
                            if (event.target.value == "") return formikChange(event);
                            if (event.target.value.match(/^\w+$/)) {
                                return formikChange(event);
                            }
                        } else if (onlyAlphabetFirst) {
                            if (event.target.value == "") return formikChange(event);
                            if (event.target.value.charAt(0).match(/^[a-zA-Z]+$/)) {
                                return formikChange(event);
                            }
                        } else return formikChange(event);
                    }
                }
                onKeyPress={(event) => {
                }}
                onMouseOver={(e) => { hoverText && setHoverText(hoverText) }} onMouseOut={(e) => { hoverText && setHoverText('') }}
                onBlur={onBlur}
                value={formikValue}
                inputProps={{
                    maxLength: maxlength ? maxlength : '100',
                    max: 10, autoComplete: 'off',
                }}
                InputProps={{
                    endAdornment: <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                            style={{ padding: '9px' }}
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>,
                }}
                placeholder={placeholder ? placeholder : ''}
            />
            {formInvalidAlerts['errShow'] &&
                <GenericModal
                    open={formInvalidAlerts['errShow']}
                    onClose={() => setFormInvalidAlerts({ errShow: false, errMessage: '' })}
                    error={formInvalidAlerts}
                    buttonText='Ok'
                    onButtonPress={() => (formik) ? formik.setFieldValue(labelId, 0) : ""}
                />}
            {(helperText) ?
                errors && helperText && <FormHelperText className="helperText">{helperText}</FormHelperText> :
                errors && touched && <FormHelperText className="helperText">{errors}</FormHelperText>}
            {onHoverText && <FormHelperText className="asterisk">{onHoverText}</FormHelperText>}
        </FormInputWrapper>
    )
}