import React, {useEffect,useState} from 'react';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components'
import BrandingColors from '../../styles/color';
import EmailTemplate from '../../components/Modal/email';
import AddAddress from '../../components/Modal/addAddress';
import AddAddressFaculty from '../../components/Modal/addAddressFaculty';
import AddCourse from '../../components/Modal/addCourse';
import AddNote from '../../components/Modal/addNote';
import AddQuestion from '../../components/Modal/addQuestion';

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};
const StyledTitle = styled(BootstrapDialogTitle)`
    background-color : ${BrandingColors.white};
    color: ${BrandingColors.darkBlack};
    border-bottom: 2px solid ${BrandingColors.fontOrangeCustom};
    display: flex;
    button{
        color: ${BrandingColors.darkBlack};
        padding: 0;
        border-radius: 0;
        height:24px;
    }
    span{
        flex:1 1 0;
        line-height: 2;
    }
`
const DialogWrapper = styled.div`
    min-width: 825px;
`
const CustomizedDialogs =  (props) =>{
    const { onClose, popupData } = props;
    const { popupHeaderTitle, popupTemplate, open, selectedTableRow, selectedIndex, type ,searchMethod} = popupData;
    const { prospectId, prospectName} = selectedTableRow;
    // console.log(`${selectedTableRow["firstName"]} ${selectedTableRow["lastName"]}`)
    const [fullName,setFullName] = useState("")
    useEffect(()=>{
        prospectName ? setFullName(prospectName) : setFullName(selectedTableRow["firstName"] +" " + selectedTableRow["lastName"])
    })
    return (
        <div>
            <Dialog
                onClose={onClose}
                maxWidth={'md'}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <StyledTitle id="customized-dialog-title" onClose={onClose}>
                    <span>{popupHeaderTitle}</span>
                    {prospectId &&
                        <>
                            <span>Prospect ID: {prospectId} </span>
                            <span>Prospect Name: {fullName} </span>
                        </>
                        }
                </StyledTitle>
                <DialogContent dividers>
                    <DialogWrapper>
                        {popupTemplate === 'email' && <EmailTemplate onClose={onClose} />}
                        {popupTemplate === 'addAddress' && <AddAddress onClose={onClose} type={type} selectedRow={selectedTableRow} selectedIndex={selectedIndex} />}
                        {popupTemplate === 'AddFacultyAddress' && <AddAddressFaculty onClose={onClose} type={type} selectedRow={selectedTableRow} selectedIndex={selectedIndex} />}
                        {popupTemplate === 'Add Notes' && <AddNote onClose={onClose} selectedRow={selectedTableRow} selectedIndex={selectedIndex} />}
                        {popupTemplate ==='addQuestion' && <AddQuestion onClose={onClose} selectedRow={selectedTableRow} selectedIndex={selectedIndex} popupHeaderTitle={popupHeaderTitle} searchMethod={searchMethod} />}
                        {popupTemplate === 'addCourse' && <AddCourse onClose={onClose} selectedRow={selectedTableRow} selectedIndex={selectedIndex} />}
                    </DialogWrapper>
                </DialogContent>
            </Dialog>
        </div>
    );
}
export default React.memo(CustomizedDialogs);
