import React from 'react';
import { FormInputWrapper } from '../../styles/common-styles'
import { Select, InputLabel, FormControl, FormHelperText, ListSubheader, Tooltip } from '@material-ui/core';
import { getOptions, getOptionsGroups } from '../../helper';
import { useEffect, useState } from 'react';

/**
 * @param {{
 * label: string, 
 * labelId: string, 
 * formikValue: Object, 
 * formikChange: Function, 
 * isDoubled?: Boolean,
 * errors?: formik.errors.labelId,
 * touched?: formik.touched.labelId,
 * mandatory: Boolean,
 * dropdownData:Object '{optionLists,key,value}'
 * disabled: Boolean
 * sortAsc: Boolean
 * }} props 
 */
export const SelectInput = (props) => {
    const { customStyle, label, labelId, formikValue, formikChange, isDoubled, errors, touched, mandatory, dropdownData, disabled, sortAsc = false, onTooltip } = props;

    const [list, setList] = useState({ key: "", value: '', optionLists: [] })
    const [isTooltip, setTooltip] = useState(true);
    useEffect(() => {
        if (dropdownData?.optionLists?.length > 0) {
            const updatedList = dropdownData.optionLists.map((v, i) => {
                if (window.location.hash.indexOf('/system-manager/lookup-table-options/custom-grade-entry') > -1) {
                    if (v[dropdownData.key] === '' && Number(v[dropdownData.value]) === 0) {
                        v[dropdownData.key] = 'Default'
                    }
                } else {
                    if (v[dropdownData.key] === '' && Number(v[dropdownData.value]) === 0) {
                        v[dropdownData.key] = '<Select one>'
                    } else if (v[dropdownData.key] === '') {
                        v[dropdownData.key] = '<Select one>'
                    }
                }
                return v
            })
            setList({
                ...list,
                key: dropdownData.key,
                value: dropdownData.value,
                optionLists: updatedList
            })
        }
        else {
            setList({
                ...list,
                key: '',
                value: '',
                optionLists: []
            })
        }
    }, [dropdownData])


    if (sortAsc) {
        list.optionLists.sort((a, b) => {
            if (a.displayText != null) {
                // if((a.displayText).toUpperCase() === 'NONE') {
                //     return -1;
                // }
                if (a.displayText.startsWith("<") && a.displayText.endsWith(">")) {
                    return -1;
                }
                if (b.displayText.startsWith("<") && b.displayText.endsWith(">")) {
                    return 1;
                }
                if (a.displayText.toLowerCase() < b.displayText.toLowerCase()) {
                    return -1;
                }
                if (a.displayText.toLowerCase() > b.displayText.toLowerCase()) {
                    return 1;
                }
                return 0;
            }
        });
    }

    return (
        <FormInputWrapper style={customStyle}
            isDoubled={isDoubled ? isDoubled : null}
            className={isDoubled ? 'doubled' : null}
        >
            <InputLabel htmlFor={labelId} aria-label={label}>{label}<span className={(mandatory) ? 'asterisk' : ''}>{(mandatory) ? '*' : ''}</span></InputLabel>
            <FormControl variant="outlined" fullWidth>
                <Tooltip title={onTooltip && isTooltip ? onTooltip : ""} arrow>
                    <Select
                        error={(errors && touched) ? true : false}
                        id={labelId}
                        name={labelId}
                        value={formikValue}
                        aria-required={mandatory ? "true" : null}
                        inputProps={{
                            "aria-label": label,
                            "aria-required": mandatory ? "true" : null
                        }}
                        SelectDisplayProps={
                            {
                                "aria-required": mandatory ? "true" : null
                            }
                        }
                        displayEmpty
                        disabled={disabled ? disabled : false}
                        onChange={formikChange}
                        onOpen={() => setTooltip(false)}
                        onClose={() => setTooltip(true)}
                    >
                        {getOptions(list)}

                    </Select>
                </Tooltip>
                {errors && touched && <FormHelperText className="helperText">{errors}</FormHelperText>}
            </FormControl>
        </FormInputWrapper>
    )
}
/**
 * @param {{
 * label: string, 
 * labelId: string, 
 * formikValue: Object, 
 * formikChange: Function, 
 * isDoubled?: Boolean,
 * errors?: formik.errors.labelId,
 * touched?: formik.touched.labelId,
 * mandatory: Boolean,
 * dropdownData: Object
 * }} props 
 */
export const SelectGroupInput = (props) => {
    const { label, labelId, formikValue, formikChange, isDoubled, errors, touched, mandatory, dropdownData, disabled } = props;
    const { optionLists, key, value, groupLabel, groupOne, groupTwo } = dropdownData;
    return (
        <FormInputWrapper
            isDoubled={isDoubled ? isDoubled : null}
            className={isDoubled ? 'doubled' : null}
        >
            <InputLabel aria-required={mandatory ? "true" : null} aria-label={label} htmlFor={labelId}>{label}<span className={(mandatory) ? 'asterisk' : ''}>{(mandatory) ? '*' : ''}</span></InputLabel>
            <FormControl variant="outlined" fullWidth>
                <Select
                    error={(errors && touched) ? true : false}
                    id={labelId}
                    name={labelId}
                    value={formikValue}
                    aria-required={mandatory ? "true" : null}
                    inputProps={{
                        "aria-required": mandatory ? "true" : null,
                        "aria-label": label
                    }}
                    SelectDisplayProps={
                        {
                            "aria-required": mandatory ? "true" : null
                        }
                    }
                    displayEmpty
                    disabled={disabled}
                    onChange={formikChange}>
                    <ListSubheader style={{ pointerEvents: 'none' }}>{groupOne}</ListSubheader>
                    {getOptionsGroups(optionLists, key, value, true, groupLabel)}
                    {/* {groupTwo && <> */}
                    <ListSubheader style={{ pointerEvents: 'none' }}>{groupTwo}</ListSubheader>
                    {getOptionsGroups(optionLists, key, value, false, groupLabel)}
                    {/* </> } */}
                </Select>
                {errors && touched && <FormHelperText className="helperText">{errors}</FormHelperText>}
            </FormControl>
        </FormInputWrapper>
    )
}

