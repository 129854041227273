export const financialAid_transfer =[
    { name: 'Financial Aid', path: '/FinancialAid/transfer', isLink: true },
    { name: 'Award Transfer', isLink: false }

];
export const financialAid_cod =[
    { name: 'Financial Aid', path: '/FinancialAid/cod', isLink: true },
    { name: 'COD Messages', isLink: false }

];
export const financialAid_cod_disbursement =[
    { name: 'Financial Aid', path: '/FinancialAid/cod', isLink: true },
    { name: 'COD Messages', path: '/FinancialAid/cod', isLink: true },
    { name: 'Disbursement Rebuild Details', isLink: false }

];
export const selected_awards =[
    { name: 'Financial Aid', path: '/FinancialAid/transfer/selectedAwards', isLink: true },
    { name: 'Award Transfer', isLink: true },
    { name: 'Selected Awards', isLink: false }
];

export const financialAid_report_fisap =[
    { name: 'Financial Aid', path: '/financialAid/fisap', isLink: true },
    { name: 'Report', isLink: true },
    { name: 'FISAP', isLink: false }
];
export const financialAid_packaging_Single =[
    { name: 'Financial Aid', path: '/FinancialAid/packaging', isLink: true},
    { name: 'Package', isLink: true },
    { name: 'Single', isLink: false }
];
export const financialAid_packaging_Single_addPackage =[
    { name: 'Financial Aid', path: '/FinancialAid/packaging', isLink: true},
    { name: 'Package', isLink: true },
    { name: 'Single', isLink: true },
    { name: 'Setup', isLink: true },
    { name: 'Add/Update Single Packaging Setup Detail', isLink: false }
];
export const financialAid_packaging_Single_packagedAwards =[
    { name: 'Financial Aid', path: '/FinancialAid/packaging', isLink: true},
    { name: 'Package', isLink: true },
    { name: 'Single', isLink: true },
    { name: 'Package', isLink: true },
    { name: 'Edit Award Detail', isLink: false }
];
export const financialAid_report_statusAndLoad_enrollment = [
    { name: 'Financial Aid', path: '/financialAid/report/statusAndLoad/enrollement', isLink: true },
    { name: 'Report', isLink: true },
    { name: 'Status And Load', isLink: true },
    { name: 'Enrollment' , isLink: false }

];
export const financialAid_report_byor =[
    { name: 'Financial Aid', path: '/financialAid/byor', isLink: true },
    { name: 'Report', isLink: true },
    { name: 'BYOR', isLink: false }

]
export const financialAid_budget = [
    { name: 'Financial Aid', path: '/financial-aid/packaging/prioritized/budgets', isLink: true },
    { name: 'Package', isLink: true },
    { name: 'Prioritized', isLink: true },
    { name: 'Budgets' , isLink: false }

];
export const budget_copy = [
    { name: 'Financial Aid', path: '/financial-aid/packaging/prioritized/budgets', isLink: true },
    { name: 'Package', isLink: true },
    { name: 'Prioritized', isLink: true },
    { name: 'Budgets' , isLink: true },
    { name: 'Copy Budgets' , isLink: false }

];
export const add_budgets = [
    { name: 'Financial Aid', path: '/financial-aid/packaging/prioritized/budgets', isLink: true },
    { name: 'Package', isLink: true },
    { name: 'Prioritized', isLink: true },
    { name: 'Budgets' , isLink: true },
    { name: 'Add/Update Budget Detail' , isLink: false }

];
export const student_selection_lookup = [
    { name: 'Financial Aid', path: '/financial-aid/packaging/prioritized/budgets', isLink: true },
    { name: 'Package', isLink: true },
    { name: 'Prioritized', isLink: true },
    { name: 'Budgets' , isLink: true },
    { name: 'Student Selection Criteria' , isLink: false }

];

export const student_selection_lookup_package = [
    { name: 'Financial Aid', path: '/financial-aid/packaging/prioritized/budgets', isLink: true },
    { name: 'Package', isLink: true },
    { name: 'Prioritized', isLink: true },
    { name: 'Package' , isLink: true },
    { name: 'Student Selection Criteria' , isLink: false }

];

export const student_selection = [
    { name: 'Financial Aid', path: '/financial-aid/packaging/prioritized/budgets', isLink: true },
    { name: 'Package', isLink: true },
    { name: 'Prioritized', isLink: true },
    { name: 'Award Methodology' , isLink: true },
    { name: 'Student Selection Lookup' , isLink: false }

];

export const financialAid_workstudy_maintenance =[
    { name: 'Financial Aid', path: '/financial-aid', isLink: true },
    { name: 'Work Study', path: '/financial-aid/work-study', isLink: true },
    { name: 'Maintenance' , isLink: false }
];

export const financialAid_addJob = [
    { name: 'Financial Aid', path: '/financial-aid', isLink: true },
    { name: 'Work Study', path: '/financial-aid/work-study', isLink: true },
    { name: 'Maintenance' ,path: '/financial-aid/work-study/maintenance', isLink: false },
    { name: 'Add/Update Job' , isLink: false }
]
export const financialAid_searchContact = [
    { name: 'Financial Aid', path: '/billing/authorize', isLink: true },
    { name: 'Search Contact', isLink: false }
]


export const work_study_setup = [
    { name: 'Financial Aid', path: '/financialAid/setup/work-study-setup/workStudySetup', isLink: true },
    { name: 'Setup', isLink: true },
    { name: 'Work Study Setup', isLink: false },

];
export const award_reference = [
    { name: 'Financial Aid', path: '/#/financial-aid/setup/award-reference', isLink: true },
    { name: 'Setup', isLink: true },
    { name: 'Award Reference', isLink: false },

];

export const award_reference_entry = [
    { name: 'Financial Aid', path: '/#/financial-aid/setup/award-reference', isLink: true },
    { name: 'Setup', isLink: true },
    { name: 'Award Reference', isLink: true },
    { name: 'Add/Update Award Reference Entry', isLink: false },
    

];
export const award_amount = [
    { name: 'Financial Aid', path: '/#/financial-aid/setup/award-reference', isLink: true },
    { name: 'Setup', isLink: true },
    { name: 'Award Reference', isLink: true },
    { name: 'Add/Update Default Award Amount', isLink: false },
    

];
export const award_restrict_gpa = [
    { name: 'Financial Aid', path: '/#/financial-aid/setup/award-reference', isLink: true },
    { name: 'Setup', isLink: true },
    { name: 'Add/Update Restrict Award to GPA Group', isLink: false },
    

];
export const award_restrict_govt = [
    { name: 'Financial Aid', path: '/#/financial-aid/setup/award-reference', isLink: true },
    { name: 'Setup', isLink: true },
    { name: 'Add/Update Restrict Award to SAP Governmental Statuses', isLink: false },
    

];
export const award_restrict_insitute = [
    { name: 'Financial Aid', path: '/#/financial-aid/setup/award-reference', isLink: true },
    { name: 'Setup', isLink: true },
    { name: 'Add/Update Restrict Award to SAP Institutional Statuses', isLink: false },
    

];
export const yearlyCodparameter = [
    { name: 'Financial Aid', path: '/#/financial-aid/setup/award-reference', isLink: true },
    { name: 'Setup', isLink: true },
    { name: 'Award Reference', isLink: true },
    { name: 'Add/Update Yearly COD Parameters', isLink: true },
    

];
export const financialAid_report_awardLetters = [
    { name: 'Financial Aid', path: '/financial-aid', isLink: true },
    { name: 'Report', path: '/financial-aid/report', isLink: true },
    { name: 'Award Letters', isLink: true },
];
export const financialAid_packaging_awardMethodology = [
    { name: 'Financial Aid', path: '/financialAid', isLink: true },
    { name: 'Package', path: '/financial-aid/packaging', isLink: true },
    { name: 'Prioritized', path: '/financial-aid/packaging/prioritized', isLink: true },
    { name: 'Award Methodology', isLink: false },

];
export const add_FinancialAid_packaging_awardMethodology = [
    { name: 'Financial Aid', path: '/financialAid', isLink: true },
    { name: 'Package', path: '/financial-aid/packaging', isLink: true },
    { name: 'Prioritized', path: '/financial-aid/packaging/prioritized', isLink: true },
    { name: 'Award Methodology', path: '/financial-aid/packaging/prioritized/award-methodolgy', isLink: true },
    { name: 'Add/Update Award Methodology ', isLink: false },
]
export const financialAid_packaging_awardMethodology_add = [
    { name: 'Financial Aid', path: '/financialAid', isLink: true },
    { name: 'Package', path: '/financial-aid/packaging', isLink: true },
    { name: 'Prioritized', path: '/financial-aid/packaging/prioritized', isLink: true },
    { name: 'Award Methodology', path: '/financial-aid/packaging/prioritized/awardMethodology', isLink: true },
    { name: 'Add/Update Amount Based On Transdoc Sum', isLink: false },

];
export const financialAid_packaging_awardMethodology_copyAward = [
    { name: 'Financial Aid', path: '/financialAid', isLink: true },
    { name: 'Package', path: '/financial-aid/packaging', isLink: true },
    { name: 'Prioritized', path: '/financial-aid/packaging/prioritized', isLink: true },
    { name: 'Award Methodology', path: '/financial-aid/packaging/prioritized/awardMethodology', isLink: true },
    { name: 'Copy One Award Methodology', isLink: false },

];
export const financialAid_packaging_awardMethodology_copyAll = [
    { name: 'Financial Aid', path: '/financialAid', isLink: true },
    { name: 'Package', path: '/financial-aid/packaging', isLink: true },
    { name: 'Prioritized', path: '/financial-aid/packaging/prioritized', isLink: true },
    { name: 'Award Methodology', path: '/financial-aid/packaging/prioritized/awardMethodology', isLink: true },
    { name: 'Copy Award Methodology', isLink: false },

];
export const financialAid_packaging_packageCriteria = [
    { name: 'Financial Aid', path: '/financialAid', isLink: true },
    { name: 'Package', path: '/financial-aid/packaging', isLink: true },
    { name: 'Prioritized', path: '/financial-aid/packaging/prioritized', isLink: true },
    { name: 'Package Criteria', isLink: false },

];

export const financialAid_packaging_packageCriteria_show = [
    { name: 'Financial Aid', path: '/financialAid', isLink: true },
    { name: 'Package', path: '/financial-aid/packaging', isLink: true },
    { name: 'Prioritized', path: '/financial-aid/packaging/prioritized', isLink: true },
    { name: 'Package Criteria', path: '/financial-aid/packaging/prioritized/packagingCriteria', isLink: true },
    { name: '100 rows Of CAMS _Financial Status_View', isLink: false },

];

export const financialAid_packaging_packageCriteria_test = [
    { name: 'Financial Aid', path: '/financialAid', isLink: true },
    { name: 'Package', path: '/financial-aid/packaging', isLink: true },
    { name: 'Prioritized', path: '/financial-aid/packaging/prioritized', isLink: true },
    { name: 'Package Criteria', path: '/financial-aid/packaging/prioritized/packagingCriteria', isLink: true },
    { name: 'List Of Students Returned From Query', isLink: false },

];

export const financialAid_packaging_packageCriteria_addQueryBuilder = [
    { name: 'Financial Aid', path: '/financialAid', isLink: true },
    { name: 'Package', path: '/financial-aid/packaging', isLink: true },
    { name: 'Prioritized', path: '/financial-aid/packaging/prioritized', isLink: true },
    { name: 'Package Criteria', path: '/financial-aid/packaging/prioritized/packagingCriteria', isLink: true },
    { name: 'Add/Update Student Criteria Query Builder', isLink: false },

];

export const financialAid_packaging_packageCriteria_addAdvanceQueryBuilder = [
    { name: 'Financial Aid', path: '/financialAid', isLink: true },
    { name: 'Package', path: '/financial-aid/packaging', isLink: true },
    { name: 'Prioritized', path: '/financial-aid/packaging/prioritized', isLink: true },
    { name: 'Package Criteria', path: '/financial-aid/packaging/prioritized/packagingCriteria', isLink: true },
    { name: 'Add/Update Advanced Criteria Entry', isLink: false },

];

export const financialAid_packaging_packageCriteria_copy = [
    { name: 'Financial Aid', path: '/financialAid', isLink: true },
    { name: 'Package', path: '/financial-aid/packaging', isLink: true },
    { name: 'Prioritized', path: '/financial-aid/packaging/prioritized', isLink: true },
    { name: 'Package Criteria', path: '/financial-aid/packaging/prioritized/packagingCriteria', isLink: true },
    { name: 'Copy SQL Criteria', isLink: false },

];

export const add_initiate = [
    { name: 'Financial Aid', path: '/financial-aid/packaging/prioritized/budgets', isLink: true },
    { name: 'Package', isLink: true },
    { name: 'Prioritized', isLink: true },
    { name: 'Budgets' , isLink: true },
    { name: 'Available Budget' , isLink: false }

];
export const financialAid_addStudentWorker = [
    { name: 'Financial Aid', path: '/financial-aid', isLink: true },
    { name: 'Work Study', path: '/financial-aid/work-study', isLink: true },
    { name: 'Maintenance' ,path: '/financial-aid/work-study/maintenance', isLink: false },
    { name: 'Add/Update Student Worker' , isLink: false }
]
export const financialAid_addTimeSheet = [
    { name: 'Financial Aid', path: '/financial-aid', isLink: true },
    { name: 'Work Study', path: '/financial-aid/work-study', isLink: true },
    { name: 'Maintenance' ,path: '/financial-aid/work-study/maintenance', isLink: false },
    { name: 'Add/Update Time Sheet' , isLink: false }
]

export const financialAid_yearlysetup = [
    { name: 'Financial Aid', path: '/financial-aid', isLink: true },
    { name: 'Setup', path: '/financial-aid/work-study', isLink: true },
    { name: 'Yearly Setup' , isLink: false },
]

export const financialAid_yearlysetup_addAcademic = [
    { name: 'Financial Aid', path: '/financial-aid', isLink: true },
    { name: 'Setup', path: '/financial-aid/work-study', isLink: true },
    { name: 'Yearly Setup' ,path: '/financial-aid/work-study/maintenance', isLink: true },
    { name: 'Add/Update Academic Year Details' , isLink: false }
]
export const financialAid_yearlysetup_Yearlimits = [
    { name: 'Financial Aid', path: '/financial-aid', isLink: true },
    { name: 'Setup', path: '/financial-aid/work-study', isLink: true },
    { name: 'Yearly Setup' ,path: '/financial-aid/work-study/maintenance', isLink: true },
    { name: 'Add/Update Yearly Limits' , isLink: false }
]
export const financialAid_yearlysetup_addYearlimits = [
    { name: 'Financial Aid', path: '/financial-aid', isLink: true },
    { name: 'Setup', path: '/financial-aid/work-study', isLink: true },
    { name: 'Yearly Setup' ,path: '/financial-aid/work-study/maintenance', isLink: true },
    { name: 'Add/Update Academic Year Limits' , isLink: false }
]

export const financialAid_yearlysetup_addFAlimits = [
    { name: 'Financial Aid', path: '/financial-aid', isLink: true },
    { name: 'Setup', path: '/financial-aid/work-study', isLink: true },
    { name: 'Yearly Setup' ,path: '/financial-aid/work-study/maintenance', isLink: true },
    { name: 'Add/Update Financial Aid Year Limits' , isLink: false }
]

export const financialAid_package = [
    { name: 'Financial Aid', path: '/financial-aid/packaging/prioritized/package', isLink: true },
    { name: 'Package', isLink: true },
    { name: 'Prioritized', isLink: true },
    { name: 'Package' , isLink: true },

];
export const No_Show_Awards_Removal = [
    { name: 'Tools', path: '/tools/processes/financial-aid/no-show-remove', isLink: true },
    { name: 'Processes', isLink: true },
    { name: 'Financial Aid', isLink: true },
    { name: 'No Show Remove' , isLink: false },

];
export const packaging_copy = [
    { name: 'Financial Aid', path: '/financial-aid/packaging/prioritized/package', isLink: true },
    { name: 'Package', isLink: true },
    { name: 'Prioritized', isLink: true },
    { name: 'Package' , isLink: true },
    { name: 'Copy Packages' , isLink: false }

];
export const add_packages = [
    { name: 'Financial Aid', path: '/financial-aid/packaging/prioritized/packages', isLink: true },
    { name: 'Package', isLink: true },
    { name: 'Prioritized', isLink: true },
    { name: 'Package' , isLink: true },
    { name: 'Add/Update Package Detail' , isLink: false }

];
export const award_methodology = [
    { name: 'Financial Aid', path: '/financial-aid/packaging/prioritized/packages', isLink: true },
    { name: 'Package', isLink: true },
    { name: 'Prioritized', isLink: true },
    { name: 'Package' , isLink: true },
    { name: 'Award Methodologies' , isLink: false }

];
export const add_initiate_package = [
    { name: 'Financial Aid', path: '/financial-aid/packaging/prioritized/packages', isLink: true },
    { name: 'Package', isLink: true },
    { name: 'Prioritized', isLink: true },
    { name: 'Package' , isLink: true },
    { name: 'Available Packages' , isLink: false }

];
export const financialAid_Holds=[
    { name: 'Financial Aid', path: '/financial-aid/holds', isLink: true },
    { name: 'Student Holds', isLink: false },
]
export const financialAid_addDetail=[
    { name: 'Financial Aid', path: '/financial-aid', isLink: true },
    { name: 'Student Holds', path: '/financial-aid/holds', isLink: true},
    { name: 'Add/Update Business Hold', isLink: false },
]
export const financialAid_setup_loadpell = [
    { name: 'Financial Aid', path: '/financial-aid/setup/load-pell', isLink: true },
    { name: 'Setup', isLink: true },
    { name: 'Load Pell', isLink: false }

]

export const financialAid_studentStatus = [
    { name: 'Financial Aid', path: '/financial-aid', isLink: true },
    { name: 'Student Status', isLink: false }
]

export const financialAid_studentStatus_add = [
    { name: 'Financial Aid', path: '/financial-aid', isLink: true },
    { name: 'Student Status', path: '/financial-aid/student-status', isLink: true },
    { name: 'Add/Update Student Status', isLink: false }

]
export const financialAid_studentStatus_edit = [
    { name: 'Financial Aid', path: '/financial-aid', isLink: true },
    { name: 'Student Status', path: '/financial-aid/student-status', isLink: true },
    { name: 'Add/Update Student Status', isLink: false }

]
export const financialAid_studentProgram = [
    { name: 'Financial Aid', path: '/financial-aid', isLink: true },
    { name: 'Student Status', path: '/financial-aid/student-status', isLink: true },
    { name: 'Add/Update Student Program', isLink: false }

]
export const financialAid_studentStaus_Userdefined = [
    { name: 'Financial Aid', path: '/financial-aid', isLink: true },
    { name: 'Student Status', path: '/financial-aid/student-status', isLink: true },
    { name: 'User Defined', isLink: false }

]
export const financialAid_studentStaus_addExtracurricula = [
    { name: 'Financial Aid', path: '/financial-aid', isLink: true },
    { name: 'Student Status', path: '/financial-aid/student-status', isLink: true },
    { name: 'Add/Update Extra Curricular Activities', isLink: false }

]
export const financialAid_loanlogExitInterview = [
    { name: 'Financial Aid', path: '/financial-aid/loan-log-exit-interview', isLink: true },
    { name: 'Loan Log/Exit Interview', isLink: false }
   

];

export const financialAid_maintenance = [
    { name: 'Financial Aid', path: '/Home/financial-aid/maintenance', isLink: true },
    { name: 'Maintenance', isLink: false }
    
  

];
export const financialAid_report = [
    { name: 'Financial Aid', path: '/Home/financial-aid/maintenance', isLink: true },
    { name: 'Maintenance', isLink: true },
    { name: 'Report Options', isLink: false}

];
export const financialAid_borroweraward = [
    { name: 'Financial Aid', path: '/Home/financial-aid/maintenance', isLink: true },
    { name: 'Maintenance', isLink: true },
    { name: 'Add/Update Borrower Per Award Type', isLink: false}

];
export const financialAid_consellingborrower = [
    { name: 'Financial Aid', path: '/Home/financial-aid/maintenance', isLink: true },
    { name: 'Maintenance', isLink: true },
    { name: 'Add/Update Counseling data for Default Borrower', isLink: false}

];
export const financialAid_addmpn = [
    { name: 'Financial Aid', path: '/Home/financial-aid/maintenance', isLink: true },
    { name: 'Maintenance', isLink: true },
    { name: 'Add/Update MPN data for Default Borrower', isLink: false}

];
export const financialAid_costattendence = [
    { name: 'Financial Aid', path: '/Home/financial-aid/maintenance', isLink: true },
    { name: 'Maintenance', isLink: true },
    { name: 'Add/Update Cost Attendence', isLink: false}

];
export const financialAid_studentmpn = [
    { name: 'Financial Aid', path: '/Home/financial-aid/maintenance', isLink: true },
    { name: 'Maintenance', isLink: true },
    { name: 'Status', isLink: true },
    { name: 'Add/Update Student Master Promissory Note', isLink: false}

];
export const financialAid_counselling = [
    { name: 'Financial Aid', path: '/Home/financial-aid/maintenance', isLink: true },
    { name: 'Maintenance', isLink: true },
    { name: 'Status', isLink: true },
    { name: 'Add/Update Counseling', isLink: false}

];
export const financialAid_creditplus = [
    { name: 'Financial Aid', path: '/Home/financial-aid/maintenance', isLink: true },
    { name: 'Maintenance', isLink: true },
    { name: 'Status', isLink: true },
    { name: 'Add/Update Credit/PLUS Application Information', isLink: false}

];
export const financialAid_usagelimits = [
    { name: 'Financial Aid', path: '/Home/financial-aid/maintenance', isLink: true },
    { name: 'Maintenance', isLink: true },
    { name: 'Status', isLink: true },
    { name: 'Add/Update Award Usage Limits', isLink: false}

];
export const financialAid_automation = [
    { name: 'Financial Aid', path: '/Home/financial-aid/maintenance', isLink: true },
    { name: 'Maintenance', isLink: true },
    { name: 'Automation Options', isLink: false}

];
export const financialAid_finacialaid = [
    { name: 'Financial Aid', path: '/Home/financial-aid/maintenance', isLink: true },
    { name: 'Maintenance', isLink: true },
    { name: 'Add/Update Financial Aid Status', isLink: false}

];
export const financialAid_customISIR = [
    { name: 'Financial Aid', path: '/Home/financial-aid/maintenance', isLink: true },
    { name: 'Maintenance', isLink: true },
    { name: 'Add Custom ISR Records', isLink: false}

];
export const financialAid_isir = [
    { name: 'Financial Aid', path: '/Home/financial-aid/maintenance', isLink: true },
    { name: 'Maintenance', isLink: true },
    { name: 'Add/Update ISIR Details', isLink: false}

];
export const financialAid_add_isir_custom_report = [
    { name: 'Financial Aid', path: '/Home/financial-aid/maintenance', isLink: true },
    { name: 'Maintenance', isLink: true },
    { name: 'Add Custom Import Fields', isLink: false}

];
export const financialAid_financialaid = [
    { name: 'Financial Aid', path: '/Home/financial-aid/maintenance', isLink: true },
    { name: 'Maintenance', isLink: true },
    { name: 'Update Financial Aid Award Detail Entry', isLink: false}

];
export const financialAid_codHistory = [
    { name: 'Financial Aid', path: '/Home/financial-aid/maintenance', isLink: true },
    { name: 'Maintenance', isLink: true },
    { name: 'COD Export/Import History Detail', isLink: false}

];
export const financialAid_AddAwardDetails = [
    { name: 'Financial Aid', path: '/Home/financial-aid/maintenance', isLink: true },
    { name: 'Maintenance', isLink: true },
    { name: 'Add/Update Award Details', isLink: false}

];
export const financialAid_AssLoadSeq = [
    { name: 'Financial Aid', path: '/Home/financial-aid/maintenance', isLink: true },
    { name: 'Maintenance', isLink: true },
    { name: 'Add Load Doc Sequence', isLink: false}

];
export const financialAid_Adddocuments = [
    { name: 'Financial Aid', path: '/Home/financial-aid/maintenance', isLink: true },
    { name: 'Maintenance', isLink: true },
    { name: 'Add/Update Documents', isLink: false}

];
export const financialAid_userDefinedFields = [
    { name: 'Financial Aid', path: '/Home/financial-aid/maintenance', isLink: true },
    { name: 'Maintenance', isLink: true },
    { name: 'Update User Defined Fields', isLink: false}

];
export const financialAid_Email = [
    { name: 'Financial Aid', path: '/Home/financial-aid/maintenance', isLink: true },
    { name: 'Maintenance', isLink: true },
    { name: 'E-Mail', isLink: false}
    
];
export const financialAid_add_loanlogExitInterview = [
    { name: 'Financial Aid', path: '/financial-aid/loan-log-exit-interview', isLink: true },
    { name: 'Loan Log/Exit Interview', isLink: true},
    { name: 'Add/Update Loan Log', isLink: false }
   

];
export const financialAid_add_referenceDetail = [
    { name: 'Financial Aid', path: '/financial-aid/loan-log-exit-interview', isLink: true },
    { name: 'Loan Log/Exit Interview', isLink: true},
    { name: 'Add/Update Reference Details', isLink: false }
];
   
export const financialAid_lifeTimeLimits = [
    { name: 'Financial Aid', path: '/financial-aid/packaging/prioritized/life-time-limits', isLink: true },
    { name: 'Package', isLink: true },
    { name: 'Prioritized', isLink: true },
    { name: 'Lifetime Limits' , isLink: false },

];
export const add_awardTypes = [
    { name: 'Financial Aid', path: '/financial-aid/packaging/prioritized/life-time-limits', isLink: true },
    { name: 'Package', isLink: true },
    { name: 'Prioritized', isLink: true },
    { name: 'Lifetime Limits' , isLink: true },
    { name: 'Add/Update Financial Lifetime Award Types' , isLink: false },


];
export const financialAid_studentNotes=[
    { name: 'Financial Aid', path: '/financial-aid/student-notes', isLink: true },
    { name: 'Student Notes', isLink: false }
   
]
export const financialAid_addNotes=[
    { name: 'Financial Aid', path: '/financial-aid/student-notes', isLink: true },
    { name: 'Student Notes',isLink: true},
    { name: 'Add/Update Notes', isLink: false },
]
export const financialAid_addUserList=[
    { name: 'Financial Aid', path: '/financial-aid/student-notes', isLink: true },
    { name: 'Student Notes',isLink: true},
    { name: 'Groups',isLink: true},
    { name: 'User List Criteria', isLink: false },
]
export const add_update =[
    { name: 'Financial Aid', path: '/financial-aid/packaging/prioritized/life-time-limits', isLink: true },
    { name: 'Package', isLink: true },
    { name: 'Prioritized', isLink: true },
    { name: 'Lifetime Limits' , isLink: true },
    { name: 'Add/Update Financial Lifetime' , isLink: false },

]
export const add_award_methodologies = [
    { name: 'Financial Aid', path: '/financial-aid/packaging/prioritized/packages', isLink: true },
    { name: 'Package', isLink: true },
    { name: 'Prioritized', isLink: true },
    { name: 'Package' , isLink: true },
    { name: 'Add/Update Award Methodology' , isLink: false }

];

export const financialAid_yearlysetup_addFinancial = [
    { name: 'Financial Aid', path: '/financial-aid', isLink: true },
    { name: 'Setup', path: '/financial-aid/work-study', isLink: true },
    { name: 'Yearly Setup' ,path: '/financial-aid/work-study/maintenance', isLink: true },
    { name: 'Add/Update Financial Aid Year Details' , isLink: false }
]

export const financialAid_yearlysetup_addPkgTerm = [
    { name: 'Financial Aid', path: '/financial-aid', isLink: true },
    { name: 'Setup', path: '/financial-aid/work-study', isLink: true },
    { name: 'Yearly Setup' ,path: '/financial-aid/work-study/maintenance', isLink: true },
    { name: 'Add/Update Financial Calendar Details' , isLink: false }
]

export const financialAid_yearlysetup_addBudget = [
    { name: 'Financial Aid', path: '/financial-aid', isLink: true },
    { name: 'Setup', path: '/financial-aid/work-study', isLink: true },
    { name: 'Yearly Setup' ,path: '/financial-aid/work-study/maintenance', isLink: true },
    { name: 'Add/Update Cost Label Detail' , isLink: false }
]

export const financialAid_yearlysetup_addpackageterm = [
    { name: 'Financial Aid', path: '/financial-aid', isLink: true },
    { name: 'Setup', path: '/financial-aid/work-study', isLink: true },
    { name: 'Yearly Setup' ,path: '/financial-aid/work-study/maintenance', isLink: true },
    { name: 'Add/Update Package Term Limit' , isLink: false }
]

export const financialAid_yearlysetup_addterm = [
    { name: 'Financial Aid', path: '/financial-aid', isLink: true },
    { name: 'Setup', path: '/financial-aid/work-study', isLink: true },
    { name: 'Yearly Setup' ,path: '/financial-aid/work-study/maintenance', isLink: true },
    { name: 'Add/Update Term Limits' , isLink: false }
]
export const _addStudentActivity = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Processes', path: '/tools/processes', isLink: true },
    { name: 'Admission Module', path: '/tools/processes/admission-module', isLink: true },
    { name: 'Add Student Activity', path:'/tools/processes/admission-module/add-student-activity', isLink: false }
]

export const award_methodology_add_student = [
    { name: 'Financial Aid', path: '/financial-aid', isLink: true },
    { name: 'Package', path: '/financial-aid/packaging', isLink: true },
    { name: 'Prioritized', path: '/financial-aid/packaging/prioritized', isLink: true },
    { name: 'Award Methodology', path: '/financial-aid/packaging/prioritized/awardMethodology', isLink: true },
    { name: 'Add/Update Amount Based on Student Load', isLink: false },
]

export const award_methodology_add_pell = [
    { name: 'Financial Aid', path: '/financial-aid', isLink: true },
    { name: 'Package', path: '/financial-aid/packaging', isLink: true },
    { name: 'Prioritized', path: '/financial-aid/packaging/prioritized', isLink: true },
    { name: 'Award Methodology', path: '/financial-aid/packaging/prioritized/awardMethodology', isLink: true },
    { name: 'Add/Update Amounts Based on Pell SQL Function', isLink: false },
]

export const award_methodology_add_remaining_need = [
    { name: 'Financial Aid', path: '/financial-aid', isLink: true },
    { name: 'Package', path: '/financial-aid/packaging', isLink: true },
    { name: 'Prioritized', path: '/financial-aid/packaging/prioritized', isLink: true },
    { name: 'Award Methodology', path: '/financial-aid/packaging/prioritized/awardMethodology', isLink: true },
    { name: 'Add/Update Amount Based on Remaining Need', isLink: false },
]

export const financial_aid_search_student = [
    { name: 'Financial Aid', path: '/financial-aid/student-search', isLink: true },
    { name: 'Search Student', isLink: false }
]
export const academic_financial_student_program = [
    { name: 'Financial Aid', path: '/financial-aid', isLink: true },
    { name: 'Student Status', path: '/financial-aid/student-status', isLink: true },
    { name: 'Add/Update Student Programs', isLink: false }
];
export const financialAid_DocumentTracking = [
    { name: 'Financial Aid', path: '/financial-aid/document-tracking', isLink: true },
    { name: 'Document Tracking', isLink: false }
];
export const financialAid_loadColleges = [
    { name: 'Financial Aid', path: '/financial-aid/document-tracking', isLink: true },
    { name: 'Document Tracking', path: '/financial-aid/document-tracking', isLink: true },
    { name: 'Load Colleges', isLink: false }
];
export const financialAid_addDocTracking = [
    { name: 'Financial Aid', path: '/financial-aid/document-tracking', isLink: true },
    { name: 'Document Tracking', path: '/financial-aid/document-tracking', isLink: true },
    { name: 'Add/Update Document Tracking', isLink: false }
];
export const financialAid_loadDocSequence = [
    { name: 'Financial Aid', path: '/financial-aid/document-tracking', isLink: true },
    { name: 'Document Tracking', path: '/financial-aid/document-tracking', isLink: true },
    { name: 'Load Document Sequence', isLink: false }
];
