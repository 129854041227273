import { Card, CardContent } from '@material-ui/core'
import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next';
import DefaultTable from '../../../../utils/Table/DefaultTable';
import HeaderTitle from '../../../../utils/HeaderTitle/HeaderTitle'
import AddQuestion from './addquestion';
import { useSelector } from 'react-redux';
import { CRSEvalMasterSelector } from '../../../../redux/tools/CRSEvalMaster/mass-add-audit/selectors';
import { deleteApi, getApi } from '../../../../services/apiBase';
import { crs_eval_master_question } from '../../../../services/apiURL';
import { toastMessage } from '../../../../utils/Message/toasts';
import TabContext from '../../../../utils/ScrollableTab/tab-helpers/TabContext';
import { useStyles } from '../../../../styles/common-styles';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { getSelectedQuestionList } from '../../../../redux/tools/CRSEvalMaster/mass-add-audit/action';
import { updateAccessSelector } from '../../../../redux/common/roleAccess/selectors';
import { evaluationSetup } from "../../../../helper/constants";
import GenericModal from '../../../../components/Modal/GenericModal';

const Question = ({ addData, setSelectedQuestion, tabIndex }) => {

    const { t } = useTranslation(["translation", "toolsHeadCells", "tools"]);
    const { access } = useSelector(updateAccessSelector)
    const { nestedParentLevel } = access;
    const { formList } = useSelector(CRSEvalMasterSelector)
    const dispatch = useDispatch()
    const [QuestionSetup] = useState([
        { id: 'questionID', numeric: true, disablePadding: false, label: t('QuestionSetup.questionId', { ns: 'toolsHeadCells' }) },
        { id: 'questionText', numeric: true, disablePadding: false, label: t('QuestionSetup.questionText', { ns: 'toolsHeadCells' }) },
        { id: 'isNarr', numeric: true, disablePadding: false, label: t('QuestionSetup.isNarr', { ns: 'toolsHeadCells' }) },
        { id: 'isMC', numeric: true, disablePadding: false, label: t('QuestionSetup.isMc', { ns: 'toolsHeadCells' }) },
        { id: 'isNum', numeric: true, disablePadding: false, label: t('QuestionSetup.isNum', { ns: 'toolsHeadCells' }) },
        { id: 'numberMin', numeric: true, disablePadding: false, label: t('QuestionSetup.questionMin', { ns: 'toolsHeadCells' }) },
        { id: 'numberMax', numeric: true, disablePadding: false, label: t('QuestionSetup.questionMax', { ns: 'toolsHeadCells' }) },
        { id: 'mcResponseID', numeric: true, disablePadding: false, label: t('QuestionSetup.mcResponce', { ns: 'toolsHeadCells' }) },
        { id: 'insertUserId', numeric: true, disablePadding: false, label: t('QuestionSetup.insertUserId', { ns: 'toolsHeadCells' }) },
        { id: 'insertTime', numeric: true, disablePadding: false, label: t('QuestionSetup.insertTime', { ns: 'toolsHeadCells' }) },
        { id: 'updateUserId', numeric: true, disablePadding: false, label: t('QuestionSetup.updateUser', { ns: 'toolsHeadCells' }) },
        { id: 'updateTime', numeric: true, disablePadding: false, label: t('QuestionSetup.updateTime', { ns: 'toolsHeadCells' }) },
        { id: 'sectionHeading', numeric: true, disablePadding: false, label: t('QuestionSetup.sectionHead', { ns: 'toolsHeadCells' }) },
        { id: 'crsEvalQuestCategoryID', numeric: true, disablePadding: false, label: t('QuestionSetup.crsEvaluationQuestions', { ns: 'toolsHeadCells' }) },
    ])

    const [tableRow, settableRow] = useState([])

    const [isLoading, setIsLoading] = useState(false)

    const getData = () => {
        if (formList) {
            setIsLoading(true)
            getApi(`${crs_eval_master_question}/${formList.formId}`).then((resp) => {
                settableRow(resp.data.data)
                DefaultTable.resetTable("questionID");
                setIsLoading(false)
            }).catch((err) => {
                console.log(err);
            })
        }
    }


    const [showAddData, setShowAddData] = useState(false)

    const handleEditRow = (value) => {
        setShowAddData(value);
        addData(true)
    }
    const handleDeleteRow = () => {
        if (selectedRow?.questionID) {
            setIsLoading(true);
            deleteApi(`${crs_eval_master_question}/${selectedRow?.questionID}`).then((resp) => {
                if (resp.data.isSuccess) {
                    toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullyDeleted'));
                    DefaultTable.resetTable("questionID");
                    getData();
                }
            }).catch(err => {
                console.log(err);
                setIsLoading(false);
            })
        }
    }
    const [alertMax, setALertMax] = useState(false)
    const handleAddQuestion = (value) => {
        if (Number(formList.maxQuestions) <= Number(tableRow.length)) {
            setALertMax(true)
        }
        else{
        setShowAddData(value);
        setSelectedRow('');
        addData(true)
        }
    }
    const [selectedRow, setSelectedRow] = useState('');

    const onClose = (value) => {
        setShowAddData(value);
        addData(false);
        getData()
        document.getElementsByTagName('html')[0].classList.remove('popup-Page');
    }

    useEffect(() => {
        getData()
    }, [])


    const handleRowSelect = (val) => {
        setSelectedRow(val)
    }
    const classes = useStyles();

    const formik = useFormik({
        initialValues: {},
        onSubmit: (values) => {
        },

    })
    const {
        getCurrentTabsFormSnapshot,
        previousTab,
        nextTab
    } = useContext(TabContext);

    useEffect(() => {
        getCurrentTabsFormSnapshot(tabIndex, formik);
    }, []);

    useEffect(() => {
        if (selectedRow) {
            setSelectedQuestion(selectedRow)
            dispatch(getSelectedQuestionList(selectedRow))
            return;
        }
        setSelectedQuestion('')
        dispatch(getSelectedQuestionList(''))
    }, [selectedRow])
    const [evaluationSetupAccess, setEvaluationSetupAccess] = useState({
        evaluationSetup : 2,  
       })
       useEffect(() => {
         let evaluationSetupAccess = nestedParentLevel.filter((v, i) =>   
           v.menuItemName.trim() === evaluationSetup && v.moduleorder === 13  
         )  
         setEvaluationSetupAccess({ 
           ...evaluationSetupAccess, 
           evaluationSetup: (evaluationSetupAccess.length > 0) ? evaluationSetupAccess[0].menuAccess : 2,  
         })
       }, [access])   
       const [accessFlag, setAccessFlag] = useState(false)  
       useEffect(() => { 
         (evaluationSetupAccess.evaluationSetup === 1) ? setAccessFlag(true) : setAccessFlag(false) 
       }, [evaluationSetupAccess])
            //Role Based Access implementation ends here

    return (
        <>
        <GenericModal
                title={"The maximum number of questions has been reached."}
                open={alertMax}
                onClose={setALertMax}
                buttonText={t('buttonLabel.ok')}
            />
            {(!showAddData) ?
                <>
                    <form onSubmit={formik.handleSubmit} className={classes.root}>
                        <Card>
                            <CardContent>
                                <HeaderTitle variant={'subHeading'} headerTitle={t('commonLabels.questionSetup', { ns: 'tools' })} buttonText={t('buttonLabel.add')} addData={handleAddQuestion} disableButton={evaluationSetupAccess.evaluationSetup !== 2 ? true : false}/>
                                <DefaultTable onRowSelect={handleRowSelect} isLoading={isLoading} uniqueKey={'questionID'} trackingLabel={"QuestionSetup"} headCells={QuestionSetup} rows={tableRow} onEdit={handleEditRow} pagination={true} onDelete={handleDeleteRow}
                                 deleteDisable={evaluationSetupAccess.evaluationSetup !== 2 ? true : false} />
                            </CardContent>
                        </Card>
                    </form>
                </> :
                <AddQuestion onClose={onClose} accessFlag={accessFlag} selectedRow={selectedRow} selectedRowForm={formList} />
            }
        </>
    )
}
export default Question