import React from 'react';
import { Button, Card, CardContent } from '@material-ui/core';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import HeaderTitle from '../../../utils/HeaderTitle/HeaderTitle';
import { ButtonWrapperFilter, FormGroup, Splitter, useStyles } from '../../../styles/common-styles';
import { CheckBoxInput, MultipleSelectInput, TextInput } from '../../../components/Forms';

const StudentPortal = () => {
    const { t } = useTranslation('facultyManager');
    const classes = useStyles();

    const validationSchema = yup.object({

    });

    const initialValues = {
        registerInterm: [],
        expectedEntranceTerm: [],
        accountsAlreadyLinked: false,
        formula: "",
        samAccountNameFrom: "",
        samAccountNameTo: "",
        LDAPSearchString: ""
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            console.log(values)
        }
    });
    return (
        <>
            <HeaderTitle headerTitle={t('activeDirectory.linkActiveDirectoryAccountsToStudentPortal', { ns: 'facultyManager' })} help={true} />
            <form onSubmit={formik.handleSubmit} autoComplete="off">
                <Card className={classes.root}>
                    <CardContent>
                        <HeaderTitle variant={'subHeading'} headerTitle={t("activeDirectory.activeDirectoryToStudentPortal", { ns: "facultyManager" })} />
                        <FormGroup>
                            <MultipleSelectInput isDoubled list={[]} label={t('commonLabel.extraCurricularActivity', { ns: 'translation' })} labelId='registerInterm' formik={formik} />
                            <MultipleSelectInput isDoubled list={[]} label={t('commonLabel.extraCurricularActivity', { ns: 'translation' })} labelId='expectedEntranceTerm' formik={formik} />
                        </FormGroup>
                        <FormGroup>
                            <CheckBoxInput isDoubled
                                checked={formik.values.accountsAlreadyLinked}
                                label={t("activeDirectory.displayOnlyAccountsAlreadyLinked", { ns: "facultyManager" })}
                                labelId={"accountsAlreadyLinked"}
                                formikValue={formik.values.accountsAlreadyLinked}
                                formikChange={formik.handleChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <TextInput label={t('activeDirectory.formula', { ns: 'facultyManager' })} labelId='formula' formikValue={formik.values.formula} formikChange={formik.handleChange} isDoubled={true} />
                            <TextInput label={t('activeDirectory.samAccountNameFrom', { ns: 'facultyManager' })} labelId='samAccountNameFrom' formikValue={formik.values.StudentNameRangeFrom} formikChange={formik.handleChange} isDoubled={true} />
                        </FormGroup>
                        <FormGroup>
                            <TextInput label={t('activeDirectory.samAccountNameTo', { ns: 'facultyManager' })} labelId='samAccountNameTo' formikValue={formik.values.samAccountNameTo} formikChange={formik.handleChange} isDoubled={true} />
                            <TextInput label={t('activeDirectory.LDAPSearchString', { ns: 'facultyManager' })} labelId='LDAPSearchString' formikValue={formik.values.LDAPSearchString} formikChange={formik.handleChange} isDoubled={true} />
                        </FormGroup>
                    </CardContent>
                    <CardContent>
                        <ButtonWrapperFilter>
                            <Button aria-label={t('buttonLabel.lookupStudentInActiveDirectory', { ns: 'facultyManager' })} className='BgYellowOrange' variant="contained" size="large" type="submit">
                                {t('buttonLabel.lookupStudentInActiveDirectory', { ns: 'facultyManager' })}
                            </Button>
                            <Button
                                aria-label={"Cancel"}
                                className="BgLightBlue"
                                variant="contained"
                                size="large"
                            >
                                {t("buttonLabel.reset", { ns: 'facultyManager' })}
                            </Button>
                        </ButtonWrapperFilter>
                    </CardContent>
                </Card>
                <Splitter/>
                <Card>
                    <CardContent>
                        
                    </CardContent>
                </Card>
            </form>
        </>
    )
}


export default StudentPortal;