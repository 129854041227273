import { CircularProgress } from "@material-ui/core"
import React, { useEffect } from "react"


const CustomLoader = () => { 
useEffect(()=>{
    document.getElementsByTagName('body')[0].classList.add('loader-external');
    return(()=>{
        document.getElementsByTagName('body')[0].classList.remove('loader-external');
    })
},[])

    return (
        <div className="loader_bg" style={{
            backgroundColor: 'white', 
            position: 'absolute', 
            width:'100%',
            height:'100%', 
            top: '50%', left: '50%', 
            transform: 'translate(-50%, -50%)',
            display: 'flex', justifyContent:'center', alignItems: 'center',
            zIndex: 10,
        }}>
        <CircularProgress style={{color: '#6AC5DA'}} size='2em'/>
    </div>
            )
}

export default CustomLoader